import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const BlogPost = styled.article`
  flex-grow: 1;
`

export default function Template({ data, pageContext }) {
  const { next, previous } = pageContext
  const { mdx } = data // data.markdownRemark holds your post data
  const { frontmatter, body } = mdx
  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.spoiler} />
      <BlogPost>
        <BlogTitle>
          <h1>{frontmatter.title}</h1>
          <p>{frontmatter.date}</p>
        </BlogTitle>
        <MDXRenderer>{body}</MDXRenderer>
      </BlogPost>
      <NavigationLinks>
        {previous && (
          <div className="previous-link">
            <Link to={`/blog/${previous.slug}`}>
              ← {previous.frontmatter.title}
            </Link>
          </div>
        )}
        {next && (
          <div className="next-link">
            <Link to={`/blog/${next.slug}`}>{next.frontmatter.title} →</Link>
          </div>
        )}
      </NavigationLinks>
    </Layout>
  )
}

const BlogTitle = styled.div`
  h1 {
    margin-bottom: 0.3em;
  }

  p {
    margin-top: 0;
  }
`

const NavigationLinks = styled.div`
  display: flex;

  > div {
    flex-grow: 1;
  }

  .next-link {
    text-align: right;
  }
`

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        date(formatString: "MMM D, YYYY")
        title
        spoiler
      }
    }
  }
`
